import { useState, useEffect, useCallback } from 'react';

function bazaarvoiceUrl() {
  // This variable comes from ENV variables
  // and defined in the webpack config `config/webpack/environment.js`
  /* eslint-disable  no-undef */
  if (!MDMS_URL) {
    throw new Error('MDMS_URL is not defined');
  }
  const BAZAARVOICE_REVIEWS_URL = `${MDMS_URL}/api/v1/bazaarvoice/reviews`;
  return BAZAARVOICE_REVIEWS_URL;
  /* eslint-enable  no-undef */
}

const useFetchBazzarvoiceReviews = (
  initialPage = 1,
  initialPerPage = 10,
  initialSort = 'latest'
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [sort, setSort] = useState(initialSort);
  const [filters, setFilters] = useState({
    stars: [],
    products: [],
    only_photos: false,
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: perPage.toString(),
        sort,
      });
      Object.entries(filters).forEach(([key, values]) => {
        if (key === 'only_photos' && values) {
          params.append(`filters[${key}]`, 'true');
        }
        if (values.length > 0) {
          values.forEach(value => {
            params.append(`filters[${key}][]`, value);
          });
        }
      });

      const response = await fetch(`${bazaarvoiceUrl()}?${params}`);
      const json = await response.json();
      setData(json);
    } catch (requestError) {
      setError(requestError);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, sort, filters]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const nextPage = () => setPage(prevPage => prevPage + 1);

  return {
    data,
    loading,
    error,
    page,
    setPage,
    perPage,
    setPerPage,
    sort,
    setSort,
    filters,
    setFilters,
    nextPage,
    refetch,
  };
};

export { useFetchBazzarvoiceReviews };
