import * as React from 'react';
import styled from "@emotion/styled";
import {renderToString} from 'react-dom/server'
import * as PropTypes from "prop-types";
import {mapKeys, capitalize, camelCase} from "lodash";
import useRAQQuestionModal from '../hooks/use_raq_question_modal';
import {connectLocation} from "./location/LocationConnectors";
import Modal from "./Modal";
import {IFramedForm} from "./IFramedForm";
import {gt, lt, has} from 'lodash';
import querystring from 'qs';
import { SchemaForm } from './forms/InnerSchemaForm';
import CTAButton from './CTAButton';
import { addDtmEvent } from './contract-event-for-dtm';

// Component for typography for specific elements, such as ®, ™ or ©
// Note - this is dumb component. It will destroy all events and such. Styled text, preferably string only.
//
// Tim C. Baller, PhD
const EmailRAQDialog = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const RAQButton = styled.css`
  padding: 10px 20px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

export const OCNameFilter = ({ children, element = 'span', ...props }) => {
  let component = children;

  if(typeof(children) !== 'string') {
    component = renderToString(children);
  }

  //component = component.replace(/®/, '<span class="reg">®</span>');

  component = component.replace(/🅫/, '<span class="mc-md">MD</span>');
  component = component.replace(/🅪/, '<span class="mc-md">MC</span>');

  let innerHTML = {__html: component};
  return React.createElement(element, {dangerouslySetInnerHTML: innerHTML, ...props});

};

export const DataTrack = ({children, track, ...props}) => {
  if(React.Children.count(children) !== 1)
    throw "DataTrack can have only 1 child"
  let child = React.Children.only(children);

  let tracked = mapKeys(props, (_v, k) => 'data-track-' + k);
  tracked['data-track'] = track;

  return React.cloneElement(child, tracked);


}
export const If = ({condition, children}) => {
  if(condition)
    return children
  return null;
}
If.displayName = 'If';

export class TabSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIdx: props.initialIdx || 0
        }
    }

    render() {
        // @type TabSelector
        const tabs = React.Children.toArray(this.props.children);
        const headers = [];
        for (const tabKey in tabs) {
            const tab = tabs[tabKey];

            const tabActive = parseInt(this.state.activeIdx) === parseInt(tabKey);
            const activate = () => {
                this.setState({activeIdx: tabKey})
            }

            headers.push(React.cloneElement(tab, {type: "tab", selected: tabActive, activate: activate}));
        }
        const content = React.cloneElement(tabs[this.state.activeIdx], {type: "content"})
        return <React.Fragment>
            <div className={this.props.className}>
                {headers}
            </div>
            <div>
                {content}
            </div>
        </React.Fragment>
    }
}

TabSelector.propTypes = {
    children: PropTypes.any,
    initialIdx: PropTypes.any,
    className: PropTypes.string.isRequired
}

export class TabComponent extends React.Component {
    render() {
        switch (this.props.type) {
            case "tab":
                return this.renderTab();
            case "content":
                return this.renderContent();
            default:
                throw "Tab types need to have type= specified in order to render!"
        }
    }
}

TabComponent.propTypes = {
    selected: PropTypes.bool.isRequired,
    activate: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
}

export const withDelayedState = (Component, states, timeout = 2000) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
      this.methods = {}
      let stateNames;
      if(states instanceof Array) {
        stateNames = states;
      } else {
        stateNames = [states];
      }
      for (const state of stateNames) {
        this.state[state] = false;


        this.methods["on" + capitalize(camelCase(state))] = () => {
          this.setState({[state]: true});
          setTimeout(() => {
            this.setState({[state]: false});
          }, timeout)
        }
      }
    }

    render() {
      return (
        <Component {...this.props} {...this.state} {...this.methods}/>
      )
    }
  }
}

export const RequestAQuoteModal = connectLocation(({t, locale: { code }, contractor, onClose, zip, source = 'fac', portal = null, open = null, raqFormName = 'request-a-quote-form'}) => {
    let params = {};
    if (contractor) {
      params = {
        initialWidth: 0,
        parentTitle: typeof document !== 'undefined' ? document.title : '',
        parentUrl:
        typeof document !== 'undefined' ? document.location.href : '',
        zipcode: zip,
        contractor: contractor.mas_dealer_code,
        contractor_mas_dealer_code: contractor.mas_dealer_code,
        source,
        'meta[data-track-contractor-id]': contractor.mas_dealer_code,
        'meta[data-track-contractor]': contractor.store_name,
        lang: code,
        contractor_store_name: contractor.store_name,
      };
    }

    const query = `?${querystring.stringify(params)}`;
    const openController = typeof open === 'boolean' ? open : contractor;

    if (openController) {
      addDtmEvent("form",
        {
          action: "form open",
          detail: "Request a Quote",
          type: "a",
          formName: raqFormName
        },
        contractor
      );
      addDtmEvent("modal",
        {
          action: "click",
          detail: "Request a Quote",
          type: "a",
          formName: raqFormName
        },
        contractor
      );
    };

    const onSubmit = (errors) => {
      addDtmEvent("form",
        {
          action: "form submit",
          detail: "Request a Quote",
          type: "a",
          formName: raqFormName
        },
        contractor
      );
    };

    const onError = (errors) => {
       addDtmEvent("form",
        {
          action: "form error",
          detail: errors[0].property,
          type: "a",
          formName: raqFormName,
          formError: errors.flatMap(e => e.stack).join(", ")
        },
        contractor
      );
    };

    return (
    <Modal portal={portal} name={t('contractors.request_quote')} open={openController} onClose={onClose}
           className="request-quote-modal" size="sm">
      <SchemaForm
        uid={`www.owenscorning.com/${(code||'en-US').toLowerCase()}/roofing/${raqFormName}`}
        search={ query }
        afterSave={ onSubmit }
        onError={ onError }>
        <CTAButton className="oc-cta-button" element="button" text={t('submit')} type="submit" />
      </SchemaForm>
    </Modal>
  );
});
RequestAQuoteModal.WrappedComponent.displayName = 'RequestAQuoteModal';

export const EmailContractorModal = connectLocation(({t, locale: { code }, contractor, onClose, open=null, portal=null, source = 'fac', schemaOnly}) => {
  const openController = (typeof open === "boolean") ? open : Boolean(contractor);
  let params = {};

  if (contractor) {
    params = {
      meta_contractor: contractor.mas_dealer_code || contractor.membership_number,
      source,
      lang: code?.split('-')?.[0]
    }
  }

  const query = `?${querystring.stringify(params)}`;

  const schema = (
    <SchemaForm
      uid={`www.owenscorning.com/${(code||'en-US').toLowerCase()}/roofing/contractors/fac-email-me`}
      search={ query }>
      <CTAButton className="oc-cta-button" element="button" text={t('submit')} type="submit" />
    </SchemaForm>
  );

  if (schemaOnly) {
    return schema;
  }

  return (
    <Modal portal={portal} name={t('contractors.fac_email_me')} open={openController} onClose={onClose}>
      {
        contractor ? schema : null
      }
    </Modal>
  )
});
EmailContractorModal.WrappedComponent.displayName = 'EmailContractorModal';

export const EmailRAQQuestionModal = connectLocation(({t, locale: { code }, open, onClose, onAccept, onDeny, contractorId, contractorName}) => {
  return (
    <Modal open={open} onClose={onClose} data-am-region="roof-replacement-modal">
      <h2 className="h3">{t('contractors.raq_question_text')}</h2>
      <EmailRAQDialog>
        <RAQButton>
          <CTAButton
            className="oc-cta-button"
            element="button"
            text={t('yes_button')}
            onClick={onAccept}

            data-track="form-start"
            data-track-contractor-id={contractorId}
            data-track-contractor={contractorName}
            data-track-form-name="request-a-quote"
            data-track-professional="contractor"
          />
        </RAQButton>
        <RAQButton>
          <CTAButton
            className="oc-cta-button"
            element="button"
            text={t('no_button')}
            onClick={onDeny}

            data-track="form-start"
            data-track-contractor-id={contractorId}
            data-track-contractor={contractorName}
            data-track-form-name="email-contractor"
            data-track-professional="contractor"
          />
        </RAQButton>
      </EmailRAQDialog>
    </Modal>
  )
});
EmailRAQQuestionModal.WrappedComponent.displayName = 'EmailRAQQuestionModal';

export const WrappedEmailRAQQuestionModalButton = ({ contractor, children }) => {
  const {
    RAQOpen,
    RAQOnClose,
    RAQFormName,
    RAQModalElementFunc,
    emailOpen,
    emailOnClose,
    emailModalElementFunc,
    RAQQuestionOpen,
    RAQQuestionOnOpen,
    RAQQuestionOnAccept,
    RAQQuestionOnDeny,
    RAQQuestionOnClose,
  } = useRAQQuestionModal();

  const onEmailClick = contractor.show_request_a_quote ? RAQQuestionOnOpen : RAQQuestionOnDeny;

  return (
    <>
      <RequestAQuoteModal
        contractor={contractor}
        open={RAQOpen}
        portal={RAQModalElementFunc}
        raqFormName={RAQFormName}
        onClose={RAQOnClose}
      />
      <EmailContractorModal
        contractor={contractor}
        open={emailOpen}
        portal={emailModalElementFunc}
        onClose={emailOnClose}
      />
      <EmailRAQQuestionModal
        open={RAQQuestionOpen}
        onClose={RAQQuestionOnClose}
        onAccept={RAQQuestionOnAccept}
        onDeny={RAQQuestionOnDeny}
        contractorId={contractor.mas_dealer_code}
        contractorName={contractor.store_name}
      />
      {children({ onClick: onEmailClick })}
    </>
  );
};

export const localizedBaseUrl = (url) => {
  const basenameRegexp = new RegExp("(/[a-z]{2}-[a-z]{2})?/" + url)
  let baseURL = window.location.pathname.match(basenameRegexp)[0]
  return baseURL;
}

export const PrefixWithLocale = (url, locale) => {
  const isValidUrl = (text) => {
    try {
      const url = new URL(text);
    } catch (_) {
      return false;
    }
    return true;
  }

  // return if it's an absolute path (an absolute path shouldn't be localized)
  if (isValidUrl(url)) return url;

  return locale && typeof locale === 'string' ? `/${locale.toLowerCase()}${url}` : url
};

const SCROLLHOOK_DEFAULT = 50;
export class ScrollHook extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this);

    this.elRef = React.createRef();

    this.state = {
      triggered: false,
      tolerance: parseInt(this.props.tolerance) || 50
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, {passive: true})
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  getTarget() {
    if(Array.isArray(this.props.target)) {
      const viewport = document.documentElement.clientWidth;
      let defaultTarget = SCROLLHOOK_DEFAULT;

      for(const option of this.props.target) {
        // Raw object is default
        if(typeof option === "number") {
          defaultTarget = option;
        } else {
          let conditions = true;
          if(has(option, 'minWidth') && option.minWidth > viewport )
            continue;

          if(has(option, 'maxWidth') && option.maxWidth < viewport )
            continue;

          // All conditons work, we can return target
          return option.target;
        }
      }

    } else if (typeof this.props.target === "number") {
      return this.props.target;
    } else if (typeof this.props.target === "string") {
      return parseInt(this.props.target);
    }
    return SCROLLHOOK_DEFAULT;
  }
  handleScroll(e) {
    const pos = this.elRef.current.getBoundingClientRect();
    const target = this.getTarget();
    const tolerance = this.state.tolerance;
    const triggered = this.state.triggered;
    const y = pos.y;


    const inRange = (cpos, target) => (cpos > target-tolerance && cpos < target+tolerance)

    if(inRange(pos.y, target) && !triggered) {
      this.setState({triggered: true});
      this.props.trigger();
    }
    if(!inRange(pos.y, target) && triggered) {
      this.setState({triggered: false});
    }

  }
  render() {
    return (
      <div ref={this.elRef} onScroll={this.handleScroll} >
        {this.props.children}
      </div>
    )
  }
}


// Anchor that allows to link to it, but it offsets from the top of the
// screen so sticky menu don't really cover it
export const OffsetAnchor = ({id, offset = -113}) => (
  <div id={id} style={{position: 'relative', top: offset}}/>
)
