import { css } from '@emotion/react';

export default css`
  font-family: OCRoboto, Roboto, sans-serif;

  .rating-filter__label {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .rating-filter__list {
    margin: 0px;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 6px;
  }

  .rating-filter__list-item {
    border: 0.5px solid #585858;
    width: 68px;
    height: 48px;
    cursor: pointer;
  }

  .rating-filter__list-item--selected {
    background-color: #e6e6e6;
  }

  .rating-filter__list-item-checkbox {
    display: none;
  }

  .rating-filter__list-item-label {
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
