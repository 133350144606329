/* eslint-disable no-param-reassign */
function transformErrorsInner({ errors, schema, uiSchema, t }) {
  // note: custom validation errors are appended AFTER this function runs, so we won't see them here.
  return errors.map(error => {
    const propertyPath = error.property.replace(/^./, ''); // Remove the leading dot
    const schemaProperty = getPropertyFromSchema(schema, propertyPath);
    switch (error.name) {
      case 'pattern':
        error.message =
          schema?.messages?.pattern ||
          schemaProperty?.messages?.pattern ||
          t('errors.forms.pattern');
        break;
      case 'format':
        error.message =
          schema?.messages?.format ||
          schemaProperty?.messages?.format ||
          t('errors.forms.format', { format: error?.params?.format });
        break;
      case 'maxLength':
        error.message =
          schema?.messages?.maxLength ||
          schemaProperty?.messages?.maxLength ||
          t('errors.forms.max_length');
        break;
      default:
        error.message =
          schema?.messages?.required ||
          schemaProperty?.messages?.required ||
          t('errors.forms.is_required');
        break;
    }

    return error;
  });
}

// Function to get the property schema by path from the schema object
function getPropertyFromSchema(schema, path) {
  const keys = path.split('.');
  let current = schema;

  for (let key of keys) {
    if (current.properties && current.properties[key]) {
      current = current.properties[key];
    } else {
      return null; // Path doesn't exist in schema
    }
  }

  return current;
}

// RJSF only passes errors and uiSchema, so we'll have to closure t and schema into a callback.
export function makeErrorTransformer(t, schema) {
  return function transformErrors(errors, uiSchema) {
    return transformErrorsInner({ errors, schema, uiSchema, t });
  };
}
