import { css } from '@emotion/react';

export default css`
  .oc-modal__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 101;
    border: 0;
    background: transparent;
    cursor: pointer;
  }

  .bv_review_modal__content {
    padding: 50px 50px 30px 50px;
    background-color: white;
    position: relative;
  }

  .rating-button {
    text-transform: capitalize;
    border: none;
    color: #fff;
    background: #d40f7d;
    padding: 10px 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
  }

  .rating-button:hover {
    background: #9c0a64;
  }

  .bv_review__select_shingle_line {
    display: flex;
    gap: 30px;
  }

  .bv_review__shingle_line_selector {
    width: 250px;
  }

  .bv_review__write_review_button {
    padding-top: 24px;
  }

  .bv_review__shingle_line_selector__label {
    font-weight: 700;
    padding-bottom: 5px;
  }

  @media (width <= 768px) {
    .bv_review__select_shingle_line {
      flex-direction: column;
      gap: 15px;
    }

    .bv_review__write_review_button {
      padding-top: 5px;
    }
  }

  .rating-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  .bv_review__iframe,
  .bv_review_modal__iframed_content {
    width: 96%;
    height: 96%;
    border: 1px solid #666;
    border-radius: 10px;
    box-shadow: 2px 2px 19px #ccc;
  }
`;
