const reviewDateFormatter = date => {
  if (!date) {
    return null;
  }

  const dateParts = date.split('T')[0].split('-');
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
};

export const reviewContentItemMapper = review => ({
  id: review?.id || null,
  ratings: review?.star_rating || null,
  verifiedPurchase: review?.verified_purchase || false,
  posterNickname: review?.author?.nickname || 'N/A',
  postersZipCode: review?.author?.location,
  productName: review?.product?.name || null,
  productColor: review?.product?.color,
  reviewTitle:
    review?.title ||
    `Review about product ${review?.product?.name || review?.product?.id}`,
  reviewText: review?.text || 'No review text available.',
  reviewDate: reviewDateFormatter(review?.submitted_at) || 'N/A',
  posterImages: review?.photos || [],
  bvIsRecommended: review?.recommended,
  thumbsUp: review?.feedback_counts?.positive || 0,
  thumbsDown: review?.feedback_counts?.negative || 0,
});
